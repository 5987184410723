import * as React from 'react';

interface TextImageSectionProps {
	h2: string;
	p: string;
	imgSrc: string;
	bg?: string;
}

const TextImageSection: React.FC<TextImageSectionProps> = (
	props: TextImageSectionProps
) => {
	return (
		<section
			className={
				'flex w-full flex-col items-center justify-center bg-cover text-white md:flex-row '
			}
			style={{ backgroundImage: `url('${props.bg}')` }}
		>
			<div className="container mx-auto flex items-center justify-center px-4 py-14">
				<div className={'hidden justify-center lg:flex lg:w-1/2'}>
					<img src={props.imgSrc} alt={props.h2} />
				</div>
				<div
					className={
						'flex flex-col items-center justify-center gap-6 p-10 md:p-10 lg:w-1/2'
					}
				>
					<h2 className={'text-[32px] md:self-start md:text-[40px]'}>
						{props.h2}
					</h2>
					<p
						className={'space-y-6 text-base font-normal md:text-xl'}
						dangerouslySetInnerHTML={{
							__html: props.p
						}}
					></p>
				</div>
			</div>
		</section>
	);
};

export default TextImageSection;
