import React from 'react';
import { PageProps, graphql, useStaticQuery } from 'gatsby';

import Layout from '../../layouts/Layout';
import JobSearchForm from '../../components/JobSearchForm/JobSearchForm';
import ReadyStartSection from '../../components/ReadyStartSection/ReadyStartSection';
import TextSection from '../../components/TextSection/TextSection';
import TextImageSection from '../../components/TextImageSection/TextImageSection';
import ImageTextSection from '../../components/ImageTextSection/ImageTextSection';
import FacesCarousel from '../../components/FacesCarousel/FacesCarousel';

import menuItems from '../../constants/menuItems';
import heroCultureImg from '../../images/culture-heo.png';
import carrerImg from '../../images/career-path-illustration.png';
import safetyIcon from '../../images/safety-first-illustration.png';
import safetyBg from '../../images/safety-first-bg.png';
import coachImg from '../../images/coach-bus.png';
import sustainability from '../../images/sustainability.svg';

const IndexPage = (props: PageProps) => {
	const data = useStaticQuery(graphql`
		query {
			allSitePage {
				edges {
					node {
						pageContext
						id
					}
				}
			}
		}
	`);
	return (
		<Layout
			location={props.location}
			menuItems={menuItems}
			title={'Coach USA Careers'}
			desc={'Coach USA Careers'}
		>
			<JobSearchForm
				data={data}
				alignLeft
				imgSrc={heroCultureImg}
				h2="A career with us is more than just a job."
			></JobSearchForm>
			<TextSection
				h2="What We Believe"
				p="Our company’s top priority is always the safety of our employees and our staff. We also pride ourself in maintaining a friendly atmosphere, where the employees who work hard are recognized for their service."
			/>
			<TextImageSection
				imgSrc={safetyIcon}
				bg={safetyBg}
				h2="Safety"
				p="Safety is our absolute top priority. We have invested millions of dollars in new state-of-the-art motor coaches, which include seat belts for all passengers, automatic traction control, electronic stability control, lane-changing warning devices for buses, and GPS systems. Our team of experts can monitor safety critical features from our 24/7 control facility. Each of our operating companies holds the U.S. Department of Transportation's highest safety rating and each has stringent driver training and preventative maintenance programs to ensure full operational and legal compliance. <br/> Our employees are subject to random drug and alcohol testing consistent with federal requirements and to periodic safety refresher classes. Our drivers are also subject to hours of service rules stricter than those required by federal regulation."
			/>
			<ReadyStartSection />
			<ImageTextSection
				h2ClassList="text-[#A7CE3F]"
				button="Learn More"
				buttonHref="https://www.coachusa.com/greenroad"
				h2="Commitment to Sustainability"
				p="Our green certified system assists in reducing fuel consumption and carbon emissions. It also increases safety by providing real-time feedback to drivers on their driving style, including speed, braking, acceleration, lane-handling, and turning."
				imgSrc={coachImg}
				classList="bg-[#152B3A] text-white"
				iconSrc={sustainability}
			/>
			<FacesCarousel />
			<ImageTextSection
				reverse
				h2ClassList="text-[#4C6070]"
				h2="Career Path Training & Development"
				p="Our driving team is one of the best trained in the industry. New drivers undergo a minimum of 150 hours of classroom and behind-the-wheel training before they start in service. Even those with an existing Commercial Drivers License are required to complete more than 90 hours of theory and practical training. All drivers passing through the training school undergo the same training regardless of the vehicle they will ultimately drive and we also use state-of-the-art in-vehicle technology combined. Even after drivers have qualified, we have dedicated retraining programs to ensure our high standards are maintained."
				imgSrc={carrerImg}
				classList="bg-[#F2F9FE]"
			/>
			<ReadyStartSection />
		</Layout>
	);
};

export default IndexPage;
