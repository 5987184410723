import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

import SwiperCore, {
	Autoplay,
	Pagination,
	Navigation,
	EffectFade
} from 'swiper';

import face1 from '../../images/smiling-faces-1.jpg';
import face2 from '../../images/smiling-faces-2.jpg';
import face3 from '../../images/smiling-faces-3.jpg';
import face4 from '../../images/smiling-faces-4.jpg';
import face5 from '../../images/smiling-faces-5.jpg';
import face6 from '../../images/smiling-faces-6.jpg';
import face7 from '../../images/smiling-faces-7.jpg';

const FacesCarousel: React.FC = () => {
	const faces = [
		{
			id: 0,
			image: face1
		},
		{
			id: 1,
			image: face2
		},
		{
			id: 2,
			image: face3
		},
		{
			id: 3,
			image: face4
		},
		{
			id: 4,
			image: face5
		},
		{
			id: 6,
			image: face6
		},
		{
			id: 7,
			image: face7
		}
	];

	SwiperCore.use([Autoplay, Pagination, Navigation, EffectFade]);

	return (
		<section className={'bg-white px-1 py-14 text-center'}>
			<div className="mx-auto flex max-w-2xl flex-col gap-6 px-4 py-6">
				<h2 className={'text-4xl font-normal'}>
					Our Many Smiling Faces
				</h2>
				<p>
					At Coach USA and Megabus, we are a family. It is important
					to us that our work environment is a place that makes the
					individuals who drive our success feel safe and happy.
				</p>
			</div>

			<Swiper
				modules={[Navigation, Pagination, Autoplay]}
				spaceBetween={15}
				loop
				autoplay={{ delay: 3000 }}
				slidesPerView={2}
				centerInsufficientSlides
				centeredSlides
				centeredSlidesBounds
				pagination={{ clickable: true }}
			>
				{faces.map((face, index) => {
					return (
						<SwiperSlide key={index + face.id}>
							<img
								src={face.image}
								alt="Smiling faces"
								className="mx-auto mb-14 max-h-80"
							/>
						</SwiperSlide>
					);
				})}
			</Swiper>
		</section>
	);
};

export default FacesCarousel;
