import * as React from 'react';
import MainButton from '../MainButton/MainButton';

interface ImageTextSectionProps {
	h2: string;
	h2ClassList?: string;
	p: string;
	imgSrc: string;
	iconSrc?: string;
	button?: string;
	buttonHref?: string;
	classList?: string;
	reverse?: boolean;
}

const ImageTextSection: React.FC<ImageTextSectionProps> = (
	props: ImageTextSectionProps
) => {
	return (
		<section
			className={
				props.classList +
				' flex h-auto w-full flex-col items-center justify-center lg:flex-row ' +
				(props.reverse && ' flex-col-reverse lg:flex-row-reverse ')
			}
		>
			<div
				className={
					(props.reverse
						? ' lg:items-start lg:justify-start '
						: 'lg:justify-end ') +
					'flex justify-center p-6 lg:w-1/2 lg:p-0 '
				}
			>
				<div
					className={
						'flex flex-col items-center justify-center gap-6 px-6 lg:ml-14 lg:max-w-xl lg:items-start lg:justify-start' +
						(props.reverse &&
							' lg:ml-0 lg:mr-14 lg:justify-self-start')
					}
				>
					{props.iconSrc && (
						<img
							src={props.iconSrc}
							alt={props.h2}
							className="ml-[-45px] h-1/4"
						/>
					)}
					<h2
						className={
							'mb-3 text-center text-3xl lg:text-left ' +
							props.h2ClassList
						}
					>
						{props.h2}
					</h2>
					<p
						className={
							'text-center text-lg font-medium lg:text-left'
						}
					>
						{props.p}
					</p>
					{props.button && (
						<a
							href={props.buttonHref}
							target="_blank"
							rel="nofollow"
						>
							<MainButton>{props.button}</MainButton>
						</a>
					)}
				</div>
			</div>
			<div className={'hidden h-full w-1/2 lg:block'}>
				<img
					src={props.imgSrc}
					alt={props.h2}
					className="w-full object-cover"
				/>
			</div>
		</section>
	);
};

export default ImageTextSection;
